/* 
    We use a copy of the useToast composable that doesn't require a vue instance, so we can use it outside vue components aswell.
    The original is node_modules/primevue/toastservice/index.mjs
*/

import { ToastEventBus, type ToastMessageOptions } from "primevue";

import { singleton } from "@solvari/utils";

const useToast = singleton(() => {
  const toastEventBus = ToastEventBus as {
    emit(type: string, evt?: ToastMessageOptions | string): void;
  };

  return Object.freeze({
    add(message: ToastMessageOptions) {
      toastEventBus.emit("add", message);
    },
    remove(message: ToastMessageOptions) {
      toastEventBus.emit("remove", message);
    },
    removeGroup(group: string) {
      toastEventBus.emit("remove-group", group);
    },
    removeAllGroups() {
      toastEventBus.emit("remove-all-groups");
    },
  });
});

export default useToast;
